"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

function CategoryList({ categoryList }) {
  return (
    <div>
      <div className="flex flex-col bg-[#F5F5F5] px-4 sm:px-6">
        <motion.div initial={{ opacity: 0, y: -20, scale: 0.95 }} whileInView={{ opacity: 1, y: 0, scale: 1 }} transition={{ duration: 1, delay: 1.2, type: "spring", stiffness: 100 }} viewport={{ once: true }} className="bg-[#FCFCFC] py-2 sm:py-0 px-4 sm:px-10 max-w-[1100px]">
            <div className="flex flex-row gap-x-6 sm:gap-x-16">
                <motion.h2 initial={{ opacity: 0, y: -20, scale: 0.95 }} whileInView={{ opacity: 1, y: 0, scale: 1 }} transition={{ duration: 1, delay: 0.8, type: "spring", stiffness: 100 }} viewport={{ once: true }} className="flex items-center justify-center font-bold text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] 2xl:text-[36px] text-[#333333]">
                    Сэдвүүд
                </motion.h2>
                <div className="flex flex-row">
                    <div className="my-1 sm:my-2">
                        <motion.div initial={{ opacity: 0, y: -20, scale: 0.95 }} whileInView={{ opacity: 1, y: 0, scale: 1 }} transition={{ duration: 1, delay: 0.9, type: "spring", stiffness: 100 }} viewport={{ once: true }} className="bg-[#00CC00] w-2 sm:w-[10px] h-full"></motion.div>
                    </div>
                    <motion.span initial={{ opacity: 0, y: -20, scale: 0.95 }} whileInView={{ opacity: 1, y: 0, scale: 1 }} transition={{ duration: 1, delay: 1.0, type: "spring", stiffness: 100 }} viewport={{ once: true }} className="flex items-center justify-center font-semibold md:text-lg lg:text-[20px] text-[#333333] px-4 py-2 hidden sm:block">
                        Мэдээллийг сэдвээр сонгон хайх боломжтой.<br/>
                        Жишээ нь: Муурын тухай мэдэхийг хүсвэл амьтан гэсэн бүлэг рүү ороорой.
                    </motion.span>
                    <motion.span initial={{ opacity: 0, y: -20, scale: 0.95 }} whileInView={{ opacity: 1, y: 0, scale: 1 }} transition={{ duration: 1, delay: 1.0, type: "spring", stiffness: 100 }} viewport={{ once: true }} className="flex items-center justify-center text-center font-semibold text-sm md:text-lg lg:text-[20px] text-[#333333] px-2 block sm:hidden">
                        Мэдээллийг сэдвээр сонгон хайх боломжтой.
                    </motion.span>
                </div>
            </div>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: -20, scale: 0.95 }}
        whileInView={{ opacity: 1, y: 0, scale: 1 }}
        transition={{ duration: 1, delay: 1, type: "spring", stiffness: 100 }}
        viewport={{ once: true }}
        className="grid w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-6 sm:py-14 mb-12 md:mb-0 gap-6 justify-items-center bg-[#F5F5F5] px-5 sm:px-6"
      >
        {categoryList.length > 0 ? (
          categoryList.map((category, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-full p-2 rounded-[30px] md:border-none border border-[#E6E6E6] border-b-8 hover:border-[#40A4D8] transition duration-500 ease-in-out"
            >
              <Link
                href={"/contents-category/" + category?.attributes?.name}
                className="shadow-custom border-[#F3F3F3] border w-[120px] h-[120px] md:w-[170px] md:h-[170px] lg:w-[200px] lg:h-[200px] xl:w-[230px] xl:h-[230px] rounded-full flex items-center justify-center md:hover:border-[#40A4D8] transition duration-500 ease-in-out"
              >
                {category?.attributes?.icon?.data?.[0]?.attributes?.url ? (
                  <Image
                    id="icon"
                    src={
                      process.env.NEXT_PUBLIC_BACKEND_BASE_URL +
                      category.attributes.icon.data[0].attributes.url
                    }
                    className="rounded-full object-cover border-[5px] border-[#0099FF]"
                    alt="icon"
                    width={1000}
                    height={1000}
                  />
                ) : (
                  <div
                    className="rounded-full shadow-custom bg-gray-200 flex items-center justify-center"
                    style={{ width: 160, height: 160 }}
                  >
                    <span className="text-[20px] text-[#605E5E]">No Image</span>
                  </div>
                )}
              </Link>
              <Link
                href={"/contents-category/" + category?.attributes?.name}
                className="max-w-[230px] w-full text-center py-1 md:py-3 text-md md:text-lg lg:text-xl xl:text-2xl font-bold whitespace-nowrap text-[#F9F9F9] bg-[#00CC00] -mt-4 sm:-mt-6 rounded-md"
              >
                {category?.attributes?.name}
              </Link>
              <span className="hidden">
                {category?.attributes?.description}
              </span>
            </div>
          ))
        ) : (
          <span className="text-center text-[20px] text-[#4c4d4f] col-span-4">
            Тохирох үр дүн олдсонгүй
          </span>
        )}
      </motion.div>
    </div>
  );
}

export default CategoryList;
